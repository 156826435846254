import React from "react";
import hero1 from "../components/images/hero-1.jpg";
import hero2 from "../components/images/hero-2.jpg";
import happyGroup from "../components/images/globalimg.png";
import india from "../components/images/indiaanduk.png";
import russia from "../components/images/russia.png";
import latin from "../components/images/latiinamerica.png";
import southasia from "../components/images/southAsia.png";
import middleEast from "../components/images/middleeast.png";
import africa from "../components/images/africa.png";
import { useEffect } from "react";
import Header from "../components/Header";
import Globalimg from '../components/images/globalimg3.png'

function HeroCarousel() {
  useEffect(() => {
    const targetElem = document.getElementById("globalpre");
    if (targetElem) {
      const elementPosition =
        targetElem.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - 100; // Adjust for the 20px offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <div>
      {/* <div
        className="carousel"
        style={{ backgroundImage: `url(${"https://nilelogistics.com/wp-content/uploads/2017/10/Global-Presence-banner.jpg"})` }}
      >
        <div className="carousel-content">
          <div>
            <p className="line1">
              Join us on our journey as we plan to emprove the wellness of human
              kind overall.
            </p>
          </div>
          <div>
            <h1>
            Global Presence
            </h1>
          </div>
        </div>
      </div> */}

      <Header
        bkgImage="https://nilelogistics.com/wp-content/uploads/2017/10/Global-Presence-banner.jpg"
        title=" Global Presence"
        subtitle=" Join us on our journey as we plan to emprove the wellness of human
              kind overall."
      />

      <div className="Globalpre" id="globalpre">
        <h2>Worldwide Operations</h2>
        <ul>
          <li>
            <p>
              <strong>SGMR Pharmaceuticals Pvt Ltd</strong> has its presence in
              more than 80 countries. With established presence in South East
              Asia, Asia Pacific, Africa, North America, EU & EEA, MENA, Latin
              America and CIS countries. (Macleods is now making its presence
              felt in the global pharmaceutical market.)
            </p>
          </li>
          <li>
            <p>
              We operate our International business through various business
              models like Private Markets, Tenders, Licensing, Contract
              manufacturing and Joint Ventures.We have successfully leveraged
              its production and Research & development competency for supplying
              affordable quality pharmaceuticals across the globe. We enjoy a
              unique position in many private markets because of our “know- how”
              in marketing and highly professional sales and marketing team, who
              are the core power of our competitiveness and guarantee of our
              success.
            </p>
          </li>
          <li>
            {" "}
            <p>
              We are in the process of establishing an extensive marketing and
              distribution network in all the countries of presence and (company
              pays more attention to expand its product portfolio) via
              registration and launch of novel pharmaceutical products.
            </p>
          </li>
          <li>
            <p>
              The Regulatory Affairs team with high level of proficiency plays a
              key role in earning registrations of{" "}
              <strong>SGMR Pharmaceuticals Pvt Ltd</strong> products across the
              globe.
            </p>
          </li>
          <li>
            <p>
              <strong>SGMR Pharmaceuticals Pvt Ltd</strong> has established its
              Representative Office in the CIS countries, Latin America, South
              East Asia, Middle East and Africa looking into the business
              potential and the necessity of being at the centre of the action.
            </p>
          </li>
        </ul>
      </div>

      <div className="Global-pre">
        <div className="country-map-pre">
          <ul>
            <li>
              <ul className="county-name-pre">
                <h2>Global Registered Office Locations</h2>
                <li>India</li>
                <li>UK</li>
              </ul>
            </li>
            <li>
              <img src={india}></img>
            </li>
          </ul>
        </div>

        <div className="country-map-pre">
          <ul>
            <li>
              <ul className="county-name-pre">
                <h2>CIS markets</h2>
                <li>Russia</li>
                <li>Ukraine</li>
                <li>Kazakhstan</li>
                <li>Azerbaijan</li>
                <li>Kyrgyzstan</li>
                <li>Uzbekistan</li>
                <li>Belarus</li>
                <li>Georgia</li>
                <li>Turkmenistan</li>
              </ul>
            </li>

            <li>
              <img src={russia}></img>
            </li>
          </ul>
        </div>

        <div className="country-map-pre">
          <ul>
            <li>
              <ul className="county-name-pre">
                <h2>Latin America</h2>
                <li>Caribbean Islands</li>
                <li>Chile</li>
                <li>Colombia</li>
                <li>Peru</li>
                <li>Venezuela</li>
              </ul>
            </li>
            <li>
              <img src={latin}></img>
            </li>
          </ul>
        </div>

        <div className="country-map-pre">
          <ul>
            <li>
              <ul className="county-name-pre">
                <h2>South East Asia</h2>
                <li> Vietnam</li>
                <li>Myanmar</li>
                <li>Thailand</li>
                <li>Philippines</li>
                <li>Srilanka</li>
                <li>Malaysia</li>
                <li>Cambodia</li>
              </ul>
            </li>
            <li>
              <img src={southasia}></img>
            </li>
          </ul>
        </div>

        <div className="country-map-pre">
          <ul>
            <li>
              <ul className="county-name-pre">
                <h2>Middle East</h2>
                <li>Saudi Arabia</li>
                <li>UAE</li>
                <li>Yemen</li>
              </ul>
            </li>
            <li>
              <img src={middleEast}></img>
            </li>
          </ul>
        </div>

        <div className="country-map-pre">
          <ul>
            <li>
              <ul className="county-name-pre">
                <h2>Africa</h2>
               
                  <li>
                    <li>Angola</li>
                    <li>Cameroon</li>
                    <li>Ethiopia</li>
                    <li>Ghana</li>
                    <li>Ivory Coast</li>
                    <li>Kenya</li>
                    <li>Madagascar</li>
                    <li>Malawi</li>
                    <li>Mozambique</li>
                  </li>
                  <li>
                    <li>Namibia</li>
                    <li>South Africa</li>
                    <li>Sudan</li>
                    <li>Tanzania</li>
                    <li>Togo</li>
                    <li>Uganda</li>
                    <li>Zambia</li>
                    <li>Zimbabwe</li>
                  </li>
               
              </ul>
            </li>
            <li>
              <img src={africa}></img>
            </li>
          </ul>
        </div>
      </div>

      <div className="global-img3">
        <img src={Globalimg}/>
      </div>

    </div>
  );
}

export default HeroCarousel;
